export const spNavDropToggle = () => {
  $(function () {
    const $spNavBtn = $(".l-header__sp-nav-item");
    $spNavBtn.on("click", function () {
      if ($spNavBtn.find(".l-header__sp-nav-dropdown")) {
        $(this).toggleClass("is-drop-open");
        $(this).find(".l-header__sp-nav-dropdown").slideToggle(300);
      }
    });
  });
};
