export const modal = () => {
  document.addEventListener("DOMContentLoaded", function () {
    const modalBtns = document.querySelectorAll(".js-modal-open");
    const modalCloseBtns = document.querySelectorAll(".js-modal-close");
    const modalCloseBtnsBg = document.querySelectorAll(".js-modal-close-bg");

    const pageTopBtn = document.getElementById("js-pageTop");

    const body = document.body;

    // modal open
    modalBtns.forEach((modalBtn) => {
      modalBtn.addEventListener("click", function () {
        const modalID = this.getAttribute("data-modal");
        const targetModal = document.getElementById(modalID);
        // const parentElement = this.parentElement;
        body.classList.remove("is-header-show");
        pageTopBtn.classList.remove("is-show");
        targetModal.classList.toggle("is-modal-active");
        body.classList.toggle("is-lock");
      });
    });

    // modal close
    modalCloseBtns.forEach((modalCloseBtn) => {
      modalCloseBtn.addEventListener("click", function () {
        const parentElement = this.closest(".js-modal-item");
        parentElement.classList.toggle("is-modal-active");
        body.classList.toggle("is-lock");
      });
    });

    // modal closeBg
    // modalCloseBtnsBg.forEach((modalCloseBtnBg) => {
    //   modalCloseBtnBg.addEventListener("click", function () {
    //     console.log("move");
    //     parentElement.classList.toggle("is-modal-active");
    //     body.classList.toggle("is-lock");
    //   });
    // });
  });
};
