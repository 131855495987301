export const hambergerToggle = () => {
  $(function () {
    const body = $("body");

    $("#js-hambergur-btn").on("click", function () {
      // body.toggleClass('is-nav-open');
      // $('.c-page-top-btn').removeClass('is-show');
      if (body.hasClass("is-nav-open")) {
        body.removeClass("is-nav-open");
        // body.addClass('is-nav-close'); // 動作後、閉じる動きを外す

        $(".js-g-nav").on("transitionend", function () {
          body.removeClass("is-nav-close");
        });
      } else {
        body.addClass("is-nav-open");
        // $('.c-page-top-btn').removeClass('is-show');
      }
    });
  });
};
