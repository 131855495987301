export const topSlider = () => {
  $(function () {
    $('#js-mv-slider').on('afterChange', function () {
      $(this).find('.is-active').removeClass('is-active');
      $('.slick-current').addClass('is-active');
    });

    $('#js-mv-slider').slick({
      dots: true,
      infinite: true,
      appendDots: $('#js-mv-dots'),
      dotsClass: 'p-mv-content__slider-dots-item',
      arrows: false, // 矢印
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 1500,
      autoplaySpeed: 4000,
      autoplay: false,
      // autoplay: true,
      pauseOnFocus: false, //フォーカスで一時停止を無効
      pauseOnHover: false, //マウスホバーで一時停止を無効
      dots: true,

      // responsive: [
      //   {
      //     breakpoint: 768, // 768pxの画面幅以下の場合
      //     settings: {
      //       dots: true,
      //       appendDots: $('#js-mv-sp-dots'),
      //     },
      //   },
      //   // その他のブレークポイントに対しても設定を追加可能
      // ],
    });
  });
};
