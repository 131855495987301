import { topSlider } from "../modules/top-slider.js";

// export 文を使って関数 greet1 を定義
export const loadingAni = () => {
  $(window).bind("load", function () {
    setTimeout(() => {
      $("html").removeClass("is_loadding");
      $("body").addClass("is-MV-show");

      setTimeout(() => {
        // $('#js-mv-slider').slick('slickPlay');
      }, 2000); // ローディングが終わってから2秒後にスライダーをスタート
    }, 1000); // ページ読み込み後2秒後にローディング完了
  });
};
