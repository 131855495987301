export const accordion = () => {
  $(function () {
    $(".js-accordion").each(function () {
      $(this).click(function () {
        $(this).toggleClass("is-open");
        $(this).next().stop().slideToggle();
      });
    });

    $(".js-recruit-more-btn").each(function () {
      $(this).click(function () {
        $(this).parents(".js-recruit-more__point").toggleClass("is-open");
        $(this)
          .parents(".js-recruit-more__point")
          .find(".js-recruit-more__target")
          .stop()
          .slideToggle();

        $(".js-recruit-more-btn")
          .find(".c-recruit-card__btn-ja")
          .text("もっとみる");
        $(".js-recruit-more-btn")
          .find(".c-recruit-card__btn-en")
          .text("Learn more");

        if ($(this).parents(".js-recruit-more__point").hasClass("is-open")) {
          $(this).find(".c-recruit-card__btn-ja").text("とじる");
          $(this).find(".c-recruit-card__btn-en").text("Close");
        }
      });
    });
  });
};
