export const recruitSlider = () => {
  $(function () {
    $(".js-recruit-slider").slick({
      dots: true,
      infinite: true,
      appendDots: $(".js-recruit-dots"),
      dotsClass: "js-recruit-dots__slider-dots",
      arrows: true, // 矢印
      appendArrows: $(".js-recruit-arrow"),
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 1500,
      autoplaySpeed: 5000,
      autoplay: false,
      // autoplay: true,
      pauseOnFocus: true, //フォーカスで一時停止を無効
      pauseOnHover: true, //マウスホバーで一時停止を無効
      dots: true,
      centerMode: true,
      centerPadding: "22%",
      accessibility: false,

      responsive: [
        {
          breakpoint: 1200, // 1200pxの画面幅以下の場合
          settings: {
            centerPadding: "15%",
          },
        },
        {
          breakpoint: 1025, //1025pxの画面幅以下の場合
          settings: {
            centerPadding: "10%",
          },
        },
        {
          breakpoint: 768, // 768pxの画面幅以下の場合
          settings: {
            centerPadding: 0,
          },
        },
        // その他のブレークポイントに対しても設定を追加可能
      ],
    });

    //アコーディオンのチェック

    $(".js-recruit-slider").on(
      "beforeChange",
      function (event, slick, currentSlide, nextSlide) {
        $(slick.$slides[currentSlide])
          .find(".js-recruit-more__point")
          .hasClass("is-open");
        if (
          $(slick.$slides[currentSlide])
            .find(".js-recruit-more__point")
            .hasClass("is-open")
        ) {
          $(slick.$slides[currentSlide])
            .find(".js-recruit-more__point")
            .removeClass("is-open");
          $(slick.$slides[currentSlide])
            .find(".js-recruit-more__target")
            .slideToggle();

          $(".js-recruit-more-btn")
            .find(".c-recruit-card__btn-ja")
            .text("もっとみる");
          $(".js-recruit-more-btn")
            .find(".c-recruit-card__btn-en")
            .text("Learn more");
        }
      }
    );
  });

  let resizeTimer;
  let initialWidth = window.innerWidth;

  window.addEventListener("resize", function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      let currentWidth = window.innerWidth;
      if (currentWidth !== initialWidth) {
        location.reload();
      }
    }, 200);
  });

  document.querySelectorAll(".scrollButton").forEach((button) => {
    button.addEventListener("click", function () {
      document.getElementById("sect06").scrollIntoView({
        behavior: "smooth",
      });
    });
  });
};
