import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// ScrollTriggerをGSAPに登録
gsap.registerPlugin(ScrollTrigger);

export function fadeIn02() {
  const sections = document.querySelectorAll(".js-section02");

  sections.forEach((section) => {
    const fadeInItems = section.querySelectorAll(".js-fade-in");

    if (fadeInItems.length > 0) {
      fadeInItems.forEach((fadeInItem, index) => {
        gsap.set(fadeInItem, { opacity: 0, visibility: "hidden", y: 100 }); // 初期状態を設定
        ScrollTrigger.create({
          trigger: section,
          start: "top 200%",
          onEnter: () => {
            gsap.to(fadeInItem, {
              y: 0,
              opacity: 1,
              visibility: "visible",
              duration: ".8",
              ease: "power1.inOut",
              delay: index * 0.2,
            });
          },
          once: true,
          // markers: true,
        });
      });
    }
  });
}
