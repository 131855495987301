import Chart from "chart.js/auto";

export const myChart = () => {
  const ctx01 = document.getElementById("chart01");
  const ctx02 = document.getElementById("chart02");
  const ctx03 = document.getElementById("chart03");

  // ドーナツグラフの外周にボーダーを追加するプラグイン
  const borderPlugin = {
    // id: "borderPlugin",
    // afterDatasetsDraw(chart, args, options) {
    //   const {
    //     ctx,
    //     chartArea: { top, bottom, left, right, width, height },
    //   } = chart;
    //   const radius = Math.min(width, height) / 2;
    //   const centerX = (left + right) / 2;
    //   const centerY = (top + bottom) / 2;
    //   ctx.save();
    //   ctx.beginPath();
    //   ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    //   ctx.lineWidth = options.borderWidth || 2;
    //   ctx.strokeStyle = options.borderColor || "#000";
    //   ctx.stroke();
    //   ctx.restore();
    // },
  };

  // Chart.jsにプラグインを登録
  Chart.register(borderPlugin);

  const chart01 = new Chart(ctx01, {
    type: "doughnut",
    data: {
      //   labels: ["赤", "青", "黄"],
      datasets: [
        {
          data: [60, 40],
          backgroundColor: ["rgba(118, 187, 179, 1)", "rgba(253, 220, 14, 1)"],
          borderWidth: 0,
        },
      ],
    },
    options: {
      responsive: false,
      cutout: 53,
      plugins: {
        tooltip: false,
        // borderPlugin: {
        //   borderWidth: 4,
        //   borderColor: "black",
        // },
      },
    },
  });

  const chart02 = new Chart(ctx02, {
    type: "doughnut",
    data: {
      //   labels: ["赤", "青", "黄"],
      datasets: [
        {
          data: [9, 10, 1],
          backgroundColor: [
            "rgba(205, 207, 152, 1)",
            "rgba(253, 220, 14, 1)",
            "rgba(118, 187, 179, 1)",
          ],
          borderWidth: 0,
        },
      ],
    },
    options: {
      responsive: false,
      cutout: 53,
      plugins: {
        tooltip: false,
        // borderPlugin: {
        //   borderWidth: 4,
        //   borderColor: "black",
        // },
      },
    },
  });

  const chart03 = new Chart(ctx03, {
    type: "doughnut",
    data: {
      //   labels: ["赤", "青", "黄"],
      datasets: [
        {
          data: [70, 30],
          backgroundColor: ["rgba(118, 187, 179, 1)", "rgba(253, 220, 14, 1)"],
          borderWidth: 0,
        },
      ],
    },
    options: {
      responsive: false,
      cutout: 53,
      plugins: {
        tooltip: false,
        // borderPlugin: {
        //   borderWidth: 4,
        //   borderColor: "black",
        // },
      },
    },
  });
};
