export function customScrollbar() {
    $(window).on('load', function () {
        $('.js-custom-scroll').mCustomScrollbar({
            theme: 'my-theme',
            axis: 'y',
            // advanced: {
            //     autoExpandHorizontalScroll: true,
            //     updateOnSelectorChange: true,
            // },
        });
    });
}
