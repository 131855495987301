import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function Animation01() {
  const targets = document.querySelectorAll(".js-scroll01");

  targets.forEach((target) => {
    gsap.set(target, { opacity: 0, x: -200 }); // 初期状態を設定
    ScrollTrigger.create({
      trigger: target,
      start: "top 70%",
      onEnter: () => {
        gsap.to(target, {
          x: 0,
          opacity: 1,
          ease: "power1.inOut",
          duration: 1,
        });
      },
      once: false,
      //   markers: true,
    });
  });
}
