export const pageTopBtnAction = () => {
  $(document).ready(function () {
    const body = $("body");
    const speed = 1000;

    // ページトップボタンの制御
    function pageTop() {
      let scrollY = window.scrollY;
      if (!body.hasClass("is-nav-open")) {
        if (scrollY !== 0) {
          $(".c-page-top-btn").addClass("is-show");
          body.addClass("is-header-show");
        } else {
          $(".c-page-top-btn").removeClass("is-show");
          body.removeClass("is-header-show");
        }
      }
    }

    $(window).on("load scroll", function () {
      pageTop();
    });
  });

  $(window).on("load", function () {
    const body = $("body");
    const speed = 1000;
    // ページ遷移後の移動アニメーション

    // ページ遷移後のハッシュ処理
    let hash = window.location.hash;
    if (hash) {
      $("html, body").stop().scrollTop(0);
      let headerH = $(".l-header__inner").height();
      const position = $(hash).offset().top - headerH;
      $("html").animate({ scrollTop: position }, speed);
    }

    // スムーススクロール
    $('a[href^="#"]').click(function (e) {
      e.preventDefault(); // デフォルトのアンカー動作を無効化
      body.removeClass("is-nav-open");

      const href = $(this).attr("href");
      let headerH = $(".l-header__inner").height();
      const target = $(href === "#" || href === "" ? "html" : href);
      const position = target.offset().top - headerH;
      $("html, body").animate({ scrollTop: position }, speed);
    });
  });
};
